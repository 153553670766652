import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { lazy } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { LinearProgress } from "@mui/material";

const CorpRoute = lazy(() => import("./components/HOC/corporate_routes"));
const OnboardRoute = lazy(() => import("./components/HOC/onboard_route"));
const PrivateRoute = lazy(() => import("./components/HOC/privateRoute"));
const OutletWithProvider = lazy(() => import("./context/outlet-with-provider"));
const Account = lazy(() => import("./pages/account/account"));
const Withdraw = lazy(() => import("./pages/account/withdraw"));
const UserAuthentication = lazy(() => import("./pages/authentication"));
const CorporateCerts = lazy(() => import("./pages/certs/corporate_certs"));
const CorpMessaging = lazy(() => import("./pages/corp_messaging"));
const AddCerts = lazy(() => import("./pages/courses/add-certs"));
const AddCourse = lazy(() => import("./pages/courses/add-courses"));
const AddFAQ = lazy(() => import("./pages/courses/add_faq"));
const AddFiles = lazy(() => import("./pages/courses/add_files"));
const AddQuiz = lazy(() => import("./pages/courses/add_quiz"));
const AddSection = lazy(() => import("./pages/courses/add_section"));
const AddVideo = lazy(() => import("./pages/courses/add_video"));
const AddPicture = lazy(() => import("./pages/courses/components/add_picture"));
const ConfigureCourse = lazy(() => import("./pages/courses/configure_course"));
const Course = lazy(() => import("./pages/courses/course"));
const RequestResetEmail = lazy(() => import("./pages/forgot-password"));
const InstructorPage = lazy(() => import("./pages/instructor/instructor-page"));
const LiveCourseDetail = lazy(() => import("./pages/live_courses/live"));
const Login = lazy(() => import("./pages/login"));
const Messages = lazy(() => import("./pages/messages/messages"));
const Courses = lazy(() => import("./pages/my-courses/courses"));
const Notifications = lazy(() => import("./pages/notifications"));
const AdditionalInformation = lazy(
  () => import("./pages/onboarding/add_information")
);
const ContactInformation = lazy(
  () => import("./pages/onboarding/contact_information")
);
const PersonalInformation = lazy(
  () => import("./pages/onboarding/personal-information")
);
const ProfilePictureInformation = lazy(
  () => import("./pages/onboarding/profile_picture")
);
const Waiting = lazy(() => import("./pages/onboarding/waiting"));
const Overview = lazy(() => import("./pages/overview/overview"));
const NotFoundPage = lazy(() => import("./pages/page_not_found"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const Signup = lazy(() => import("./pages/signup"));
const CorporateTheme = lazy(() => import("./pages/theme"));
const CourseUpdates = lazy(() => import("./pages/updates"));
const Enroll = lazy(() => import("./pages/upload_student"));
const CorporateUsers = lazy(() => import("./pages/users/corporate_users"));

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ProSidebarProvider>
        <React.Suspense fallback={<LinearProgress />}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/*" element={<NotFoundPage />} />
              <Route path="/forgot-password" element={<RequestResetEmail />} />
              <Route
                path="/reset-pwd/:token/digiclass-instructor"
                element={<ResetPassword />}
              />

              {/* Onboarding  */}
              <Route element={<OnboardRoute />}>
                <Route
                  path="/authentication"
                  element={<UserAuthentication />}
                />
                <Route
                  path="/on-boarding/contact-information"
                  element={<ContactInformation />}
                />
                <Route
                  path="/on-boarding/personal-information"
                  element={<PersonalInformation />}
                />
                <Route
                  path="/on-boarding/additional-information"
                  element={<AdditionalInformation />}
                />
                <Route
                  path="/on-boarding/profile-picture"
                  element={<ProfilePictureInformation />}
                />
                <Route path="/on-boarding/waiting" element={<Waiting />} />
              </Route>
              {/* Onboarding  */}

              {/* Private routes */}
              <Route element={<PrivateRoute />}>
                <Route element={<OutletWithProvider />}>
                  <Route path="/dashboard" element={<Overview />} />

                  {/* Course management */}
                  <Route path="/courses" element={<Courses />} />
                  <Route path="/updates" element={<CourseUpdates />} />
                  <Route path="/courses/add-new" element={<AddCourse />} />
                  <Route
                    path="/courses/:course_id/video"
                    element={<AddVideo />}
                  />
                  <Route path="/courses/:course_id" element={<Course />} />
                  <Route
                    path="/live-courses/:course_id"
                    element={<LiveCourseDetail />}
                  />
                  <Route
                    path="/courses/:course_id/picture"
                    element={<AddPicture />}
                  />
                  <Route
                    path="/courses/:course_id/configure"
                    element={<ConfigureCourse />}
                  />
                  <Route
                    path="/courses/:course_id/section"
                    element={<AddSection />}
                  />
                  <Route
                    path="/courses/:course_id/files"
                    element={<AddFiles />}
                  />
                  <Route
                    path="/courses/:course_id/quiz"
                    element={<AddQuiz />}
                  />
                  <Route path="/courses/:course_id/faq" element={<AddFAQ />} />

                  {/* Notification  */}
                  <Route path="/notifications" element={<Notifications />} />

                  {/* Messages  */}
                  <Route path="/messages" element={<Messages />} />

                  {/* Account  */}
                  <Route path="/account" element={<Account />} />
                  <Route path="/withdraw" element={<Withdraw />} />
                </Route>
              </Route>
              {/* PrivateRoute  */}

              {/* CorporateRoutes  */}
              <Route element={<CorpRoute />}>
                <Route element={<OutletWithProvider />}>
                  <Route path="/corp-messages" element={<CorpMessaging />} />
                  <Route path="/add-student" element={<Enroll />} />
                  <Route path="/corporate-users" element={<CorporateUsers />} />
                  <Route
                    path="/corporate-certificates"
                    element={<CorporateCerts />}
                  />
                  <Route
                    path="/courses/:course_id/certificate"
                    element={<AddCerts />}
                  />
                  <Route path="/corporate-theme" element={<CorporateTheme />} />
                  <Route
                    path="/instructor/:instructor_id"
                    element={<InstructorPage />}
                  />
                </Route>
              </Route>
              {/* CorporateRoutes  */}
            </Routes>
          </Router>
        </React.Suspense>
      </ProSidebarProvider>
    </LocalizationProvider>
  );
};

export default App;
